import Footer from '@components/Footer';
import Header from '@components/Header';
import { useMemo } from 'react';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export default function Wrapper(props: {
  isTop?: boolean;
  isBottom?: boolean;
  isLight?: boolean;
  scrollTop?: number;
  children?: React.ReactNode;
}) {
  useMemo(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-floor">
      <Header isLight={props.isLight} />
      <div
        className={classNames(
          props.isTop ? '' : 'pt-[4.75rem]',
          props.isBottom ? '' : 'pb-32',
          'min-h-[100vh]'
        )}
      >
        {props.children}
      </div>
      <Footer></Footer>
    </div>
  );
}
