import AppLogo from "@assets/images/icon/app-logo.svg";
import DiscordActive from "@assets/images/icon/discord-active.svg";
import Discord from "@assets/images/icon/discord.svg";
import MediumActive from "@assets/images/icon/medium-active.svg";
import Medium from "@assets/images/icon/medium.svg";
import TelegramActive from "@assets/images/icon/telegram-active.svg";
import Telegram from "@assets/images/icon/telegram.svg";
import TwitterActive from "@assets/images/icon/twitter-active.svg";
import Twitter from "@assets/images/icon/twitter.svg";
import Container from "@components/layout/Container";
import { useViewport, Viewport } from "@components/ViewPort/viewportContext";
import c from "classnames";

const mediaList = [
  {
    title: "Twitter",
    icon: Twitter,
    iconActive: TwitterActive,
    path: "https://twitter.com/SolvProtocol/",
  },
  {
    title: "Medium",
    icon: Medium,
    iconActive: MediumActive,
    path: "https://solvprotocol.medium.com/",
  },
  {
    title: "Telegram",
    icon: Telegram,
    iconActive: TelegramActive,
    path: "https://t.me/SolvProtocol/",
  },
  {
    title: "Discord",
    icon: Discord,
    iconActive: DiscordActive,
    path: "https://discord.com/invite/ErDrcMhkGu",
  },
];

export default function Footer({ className }: { className?: string }) {
  const { isPC }: Viewport = useViewport();
  const moduleClassName: string = c(
    "w-full flex items-center z-20 opacity-95 py-[1.375rem]",
    isPC ? "justify-between" : "w-mobile mx-auto justify-center"
  );

  const appLogo = c("w-[14.75rem]", isPC ? "" : "hidden");
  const mediaListClass = c("flex items-center", isPC ? "" : "justify-center");

  return (
    <Container size={"second"} className={className}>
      <div className={moduleClassName}>
        <img src={AppLogo} alt="" className={appLogo} />
        <div className={mediaListClass}>
          {mediaList.map((item) => (
            <img
              src={item.icon}
              onMouseOver={(event) =>
                (event.currentTarget.src = item.iconActive)
              }
              onMouseLeave={(event) => (event.currentTarget.src = item.icon)}
              alt=""
              className="ml-[2.875rem] first:ml-0 w-[1.25rem] cursor-pointer"
              onClick={() => window.open(item.path, "_blank")}
              key={item.title}
            />
          ))}
        </div>
      </div>
    </Container>
  );
}
