import React from "react";
import "./index.css";

export default function DButton(props: {
  plain?: boolean;
  light?: boolean;
  children: React.ReactNode;
  onClick?: Function;
}) {
  return (
    <div className="d-button" onClick={() => props.onClick && props.onClick()}>
      <div
        className={`d-button__inner ${props.plain ? " d-button__plain" : ""} ${
          props.light ? " d-button__light" : ""
        }`}
      >
        {props.children}
      </div>
    </div>
  );
}
