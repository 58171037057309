import { useViewport } from '@components/ViewPort/viewportContext';
import './index.css';
import { InsightsItem } from './types';

export default function Card(data: { item: InsightsItem; index: number }) {
  const { isPC } = useViewport();

  const className = `h-[26.1875rem] rounded-[6px] mb-[2.5rem] hover:bg-white hover:border-transparent relative w-full cursor-pointer ${
    isPC
      ? 'bg-transparent border-2 top-card border-black/[.06]'
      : 'bg-white h-auto'
  }`;

  const style = {
    backgroundImage: 'url(' + data.item.coverImageURI + ')',
  };

  function openExternalLink() {
    window.open(data.item.externalLink);
  }

  return (
    <div className={className} onClick={openExternalLink}>
      <div className='w-full'>
        <div className={`p-[2.5rem] ${!isPC && 'px-[1rem]'}`}>
          <div className='text-[0.875rem] text-[#656666] font-poppins-400'>
            {data.item.date}
          </div>
          <div
            className={`mt-[1.5rem] text-[1.5rem] ${
              isPC ? 'w-[21.1875rem]' : 'w-full text-[1rem] min-h-[4rem]'
            } min-h-[6rem] text-black  leading-[1.4] font-poppins-600`}
          >
            {data.item.title}
          </div>
          <div className={`mt-[3rem] w-full ${!isPC && 'mb-[4rem] mt-0'}`}>
            <div className='flex items-center'>
              <img
                alt=''
                className={`w-[3rem] h-[3rem] rounded-[50%] mr-[1.125rem] ${
                  !isPC && 'w-[1.4rem] h-[1.4rem] mr-[0.8rem]'
                }`}
                src={data.item.author.logoURI}
                title=''
              ></img>
              <div className='font-poppins-600'>{data.item.author.name}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='w-full h-[5rem] rounded-b-[6px] bg-cover bg-center absolute bottom-0 left-0'
        style={style}
      ></div>
    </div>
  );
}
