import CardList from "@components/Featured/CardList";
import Introduce from "@components/Featured/Introduce";
import Container from "@components/layout/Container";
import Wrapper from "@components/layout/Wrapper";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function Featured() {
  return (
    <Wrapper>
      <Container>
        <Introduce />

        <QueryClientProvider client={queryClient}>
          <CardList />
        </QueryClientProvider>
      </Container>
    </Wrapper>
  );
}

export default Featured;
