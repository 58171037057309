import { List, TopCard } from '@components/Insights';
import Loading from '@components/Laoding';
import Container from '@components/layout/Container';
import Wrapper from '@components/layout/Wrapper';
import { useViewport } from '@components/ViewPort/viewportContext';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';

const queryClient = new QueryClient();

type InsightsItemAuthor = {
  name: string;
  desc: string;
  logoURI: string;
};

type InsightsItem = {
  title: string;
  date: string;
  author: InsightsItemAuthor;
  coverImageURI: string;
  desc: string;
  externalLink: string;
};

function InsightList() {
  const { isLoading, error, data } = useQuery('repoData', () =>
    fetch('https://financial-nft-labs-worker.solv.workers.dev/insights', {
      method: 'POST',
    }).then((res) => res.json())
  );

  if (isLoading) {
    return <Loading></Loading>;
  }

  if (error) {
    return <div></div>;
  }
  if (!data.success) {
    return <div></div>;
  }
  const insightList: Array<InsightsItem> = JSON.parse(
    JSON.stringify(data.result)
  );
  if (insightList.length === 0) {
    return <div></div>;
  }
  const top: InsightsItem = insightList.splice(0, 1)[0];
  const listData: {
    items: Array<InsightsItem>;
  } = {
    items: insightList,
  };
  return (
    <div className='w-full mt-[2rem]'>
      <TopCard {...top} />
      <List {...listData} />
    </div>
  );
}

export default function Insights() {
  const { isPC } = useViewport();

  return (
    <Wrapper>
      <Container>
        {!isPC && (
          <p className='text-[2.625rem] w-full text-center font-poppins-600'>
            Insights
          </p>
        )}
        <QueryClientProvider client={queryClient}>
          <InsightList />
        </QueryClientProvider>
      </Container>
    </Wrapper>
  );
}
