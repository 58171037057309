import { Result } from "@apis/model/featuredModel";
import { featuredProjectsURL } from "@apis/requestMd";
import Loading from "@components/Laoding";
import { useViewport } from "@components/ViewPort/viewportContext";
import { useQuery } from "react-query";
import Card from "./Card";

function CardList() {
  const { isPC } = useViewport();
  const className: string = `w-full mx-auto grid ${
    isPC ? "grid-cols-2" : "grid-cols-1"
  } gap-11`;

  const { isLoading, error, data } = useQuery("repoData", () =>
    fetch(featuredProjectsURL, {
      method: "POST",
    }).then((res) => res.json())
  );

  if (isLoading) {
    return <Loading></Loading>;
  }
  if (error) {
    return <div></div>;
  }

  if (!data.success) {
    return <div></div>;
  }

  const list: Result[] = data.result as Result[];

  return (
    <div className={className}>
      {list.map((item, index) => (
        <Card item={item} key={index} />
      ))}
    </div>
  );
}

export default CardList;
