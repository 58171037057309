import { InsightsItem } from "./types";
import "./index.css";
import { useViewport, Viewport } from "@components/ViewPort/viewportContext";
export default function TopCard(item: InsightsItem) {
  const { isPC }: Viewport = useViewport();
  const topStyle = {
    backgroundImage: "url(" + item.coverImageURI + ")",
  };

  function openExternalLink() {
    window.open(item.externalLink);
  }
  return (
    <div
      onClick={openExternalLink}
      className={`${
        isPC ? "h-[27rem]" : "h-[33.75rem]"
      } w-full rounded-[6px] bg-cover bg-center relative box-border top-card`}
      style={topStyle}
    >
      <div className="absolute inset-0 top-bg rounded-[6px]">
        <div className="py-[3rem] px-[2.5rem] h-full text-white">
          <div className="text-[0.875rem] font-poppins-600">{item.date}</div>
          <div className="mt-[1.5rem] w-full font-poppins-600 text-5xl break-words">
            {item.title}
          </div>
          <div
            className={`${
              isPC ? "w-1/2" : "w-full"
            } break-words mt-[2.125rem] text-[0.875rem] leading-[1.4] text-white/[0.8] font-poppins-400`}
          >
            {item.desc}
          </div>
          <div className="mt-[2.5rem] w-full ">
            <div className="flex items-center">
              <img
                alt=""
                className="w-[3rem] h-[3rem] rounded-[50%] mr-[1.125rem]"
                src={item.author.logoURI}
                title=""
              ></img>
              <div className="font-poppins-600">{item.author.name}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
