import ProjectDetail from "@components/Featured/project/Detail";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();
export default function FeaturedProjects() {
  return (
    <QueryClientProvider client={queryClient}>
      <ProjectDetail></ProjectDetail>
    </QueryClientProvider>
  );
}
