import TopLine from "@assets/images/icon/top-line.svg";
import { Popover, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function DPopover({
  mode,
  buttonText,
  buttonNode,
  children,
}: {
  mode?: string;
  buttonText?: string;
  buttonNode?: React.ReactNode;
  children: React.ReactNode;
}) {
  const [showPopover, setShowPopover] = useState(false);
  const [leaveStatus, setLeaveStatus] = useState(false);

  const handleMouseLeave = () => {
    setLeaveStatus(true);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (leaveStatus) {
        setShowPopover(false);
        setLeaveStatus(false);
      }
      clearTimeout(timeout);
    }, 360);
    return () => {
      clearTimeout(timeout);
    };
  }, [leaveStatus, showPopover]);

  let props: { show?: boolean } = { show: showPopover };
  if (mode !== "hover") {
    delete props.show;
  }
  return (
    <Popover className="relative documentation">
      {() => (
        <>
          <Popover.Button
            className={classNames(
              "text-black-60",
              "group rounded-md inline-flex items-center text-base font-medium hover:text-black"
            )}
            onMouseOver={() => {
              setShowPopover(true);
              setLeaveStatus(false);
            }}
            onMouseLeave={handleMouseLeave}
          >
            {buttonNode ? buttonNode : <span>{buttonText}</span>}
          </Popover.Button>

          <Transition
            {...props}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              onMouseOver={() => {
                setShowPopover(true);
                setLeaveStatus(false);
              }}
              onMouseLeave={handleMouseLeave}
              className="absolute cursor-default z-10 -ml-4 mt-5 transform px-2 sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 min-w-[60vw]"
            >
              <img src={TopLine} alt="" className="top-line" />
              <div className="overflow-hidden card">{children}</div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
