import { ViewportProvider } from "@components/ViewPort/viewportContext";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useRoutes } from "react-router-dom";
import routes from "../routes/index";

const App: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const routing = useRoutes(routes);
  return (
    <>
      <ViewportProvider> {routing}</ViewportProvider>
    </>
  );
};
export default App;
