import { InsightsItem } from "./types";
import Card from "./Card";
import { ReactElement } from "react";
import { useViewport } from "@components/ViewPort/viewportContext";

export default function List(data: { items: Array<InsightsItem> }) {
  function ListData() {
    const { isPC } = useViewport();
    let itemList: Array<ReactElement> = [];
    data.items.forEach((item, index) => {
      const cardData: {
        item: InsightsItem;
        index: number;
      } = {
        item: item,
        index: index,
      };
      itemList.push(<Card {...cardData} key={index} />);
    });

    return (
      <div
        className={`w-full grid ${
          isPC ? "grid-cols-2  gap-11" : "grid-cols-1 gap-1"
        }`}
      >
        {itemList}
      </div>
    );
  }

  return (
    <div className="mt-[2.375rem]">
      <ListData />
    </div>
  );
}
