import React from "react";
import "./index.css";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function DSkeleton({
  itemSize,
  visible,
  isCard,
  width,
  height,
  round,
  prefix,
  suffix,
  middle,
  children,
}: {
  itemSize?: number;
  visible: boolean;
  isCard?: boolean;
  width?: string;
  height?: string;
  round?: boolean;
  prefix?: string;
  suffix?: string;
  middle?: React.ReactNode;
  children?: React.ReactNode;
}) {
  const itemList: Array<number> = [];
  for (let i = 0; i < (itemSize || 1); i++) {
    itemList.push(i);
  }

  const styles = {
    width: width,
    height: height,
  };

  return (
    <>
      {visible ? (
        <div className="d-skeleton">
          {itemList.map((item, index) => (
            <div className="flex w-full d-skeleton__node" key={item}>
              {prefix && index === 0 ? (
                <div className="mr-2 opacity-40 text-base">{prefix}</div>
              ) : (
                ""
              )}
              <div
                className={classNames(
                  "d-skeleton__item flex-1",
                  isCard ? "d-skeleton__card" : "",
                  round ? "d-skeleton__round" : ""
                )}
                style={styles}
              ></div>
              {suffix && index === itemList.length - 1 ? (
                <div className="ml-2 opacity-40 text-base">{suffix}</div>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      ) : (
        { children }
      )}
    </>
  );
}
