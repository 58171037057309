import { CardProps, IMapping, Result } from '@apis/model/featuredModel';
import { useNavigate } from 'react-router-dom';
import IconButton from './IconButton';
import './index.css';

function Card(props: CardProps) {
  const { item } = props;
  const navigate = useNavigate();

  const handleGoTo = (item: Result) => {
    navigate(`/featured-project/${item.name}`);
  };
  const texColor = (item: Result) => {
    return mapping[item.status];
  };

  const mapping: IMapping = {
    'In preparation': '#64D2D0',
    'In development': '#439ADF',
    'Testnet launched': '#2264ED',
    // "Deployed and in use": "linear-gradient(85.27deg, #2264ED 3.82%, #64D2D0 96.18%);",
  };
  return (
    <div
      onClick={() => handleGoTo(item)}
      className='group border-box min-h-[26.19rem] h-[26.19rem] hover:shadow-2xl hover:shadow-regal-blue/30 cursor-pointer'
    >
      <nav
        className={`border-box w-full min-h-[5rem] relative rounded-[.375rem] rounded-br-none rounded-bl-none px-[.5rem]`}
        style={{
          background: `linear-gradient(180deg, ${item.topBar.startColor} -21.66%,  ${item.topBar.endColor} 142.44%)`,
        }}
      >
        <img
          src={item.logoURI}
          className='w-[5rem] h-[5rem] absolute top-[2.5rem] left-[2.5rem] rounded-[50%] shadow-2xl bg-[#fafafa]'
          alt='logo'
        />
      </nav>
      <div className='border-box w-full min-h-[21.19rem] h-[21.19rem] rounded-[.375rem] rounded-tl-none rounded-tr-none border-solid border-2 border-[rgba(0, 0, 0, 0.06)] border-t-0 py-[4rem] px-[2.5rem] group-hover:border-transparent group-hover:bg-white'>
        <h3 className='text-[1.5rem] text-[#000000]/80 min-h-[2.25rem] mb-[.75rem] flex items-center'>
          <span className='font-poppins-600'>{item.name}</span>
          <span className='inline-block text-[#646466] text-[.875rem] bg-[#E7EAEF] rounded-[.125rem] text-center px-[.5rem] h-[1.375rem] ml-[1rem]'>
            {item.tags}
          </span>
        </h3>
        <p className='text-[1rem] text-[#000000]/60 h-[6rem] font-poppins-400'>
          {item.desc}
        </p>
        <span
          className={` font-poppins-600 inline-block text-[.8125rem] min-w-[9.125rem] min-h-[1.5rem] bg-blue-100/80 rounded-[.25rem]  text-center px-[1rem] py-[.1875rem] mt-[1.5rem] mb-[1.5rem] truncate ${
            item.status === 'Deployed and in use' ? 'text-gradient' : ''
          }`}
          id={item.status}
          style={{
            color: texColor(item),
          }}
        >
          {item.status}
        </span>
        <div className='flex'>
          {item.socialMedia.map((item, index) => {
            return (
              <IconButton
                type={item.type}
                URI={item.URI}
                desc={item.desc}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Card;
