import bg from '@assets/images/featured/bg.png';
import { useViewport, Viewport } from '@components/ViewPort/viewportContext';
import c from 'classnames';
import { Link } from 'react-router-dom';

function Introduce() {
  const { isPC }: Viewport = useViewport();
  const className: string = c(
    'absolute w-[39.875ren] h-[39.875rem] right-0 top-0',
    isPC ? '' : 'hidden'
  );

  return (
    <>
      <div className='w-full flex items-center mb-[6.25rem]'>
        <div className='w-[33.75rem] mt-[4rem]'>
          <p className='font-poppins-600 text-title text-black-80 leading-[5.625rem]'>
            Featured Projects
          </p>
          <div className='font-normal	text-[1.275rem] text-black-60 mt-[1.625rem] font-poppins-400 relative z-[2]'>
            <p>
              We are proud to present projects currently incubated in our Labs.
              We expect to regularly update these projects as we go along. Check
              our{' '}
              <Link
                to='/wishlist'
                className='underline underline-offset-1 hover:text-primary1'
              >
                Wishlist
              </Link>{' '}
              to explore a wide spectrum of partnership opportunities.
            </p>
          </div>
        </div>
        <img className={`${className} z-[1]`} src={bg} alt='' />
      </div>
    </>
  );
}

export default Introduce;
