import a3 from '@assets/images/home/1.png';
import b3 from '@assets/images/home/2.png';
import c3 from '@assets/images/home/3.png';
import diamonds from '@assets/images/home/diamonds.png';
import e1 from '@assets/images/home/ecosystem/eco1.png';
import e2 from '@assets/images/home/ecosystem/eco2.png';
import e3 from '@assets/images/home/ecosystem/eco3.png';
import LinkLine from '@assets/images/icon/link-line.svg';
import Footer from '@components/Footer';
import Header from '@components/Header';
import Container from '@components/layout/Container';
import { useViewport, Viewport } from '@components/ViewPort/viewportContext';
import classnames from 'classnames';
import React, { FC, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FreeMode, Mousewheel, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import './index.css';

const datas = [
  {
    img: e1,
    subtitle: 'Financial NFT Marketplace',
    describe:
      'The first and largest marketplace for creating, managing, and trading Finanacial NFTs, including vesting, flexible, and convertible vouchers.',
    link: '/featured-project/Solv Protocol',
  },
  {
    img: e2,
    subtitle: 'NFT Installment',
    describe:
      'Decentralized NFT installment solution empowered by ERC-3525. NFT installment provides a new solution to purchase NFTs and makes expensive NFTs more affordable.',
    link: '/featured-project/Solv NFT Installment',
  },
  {
    img: e3,
    subtitle: 'NFT Lending',
    describe:
      'The first NFT lending protocol that uses DAO infrastructure to provide ensured and instant liquidity to borrowers.',
    link: '/featured-project/Taker',
  },
];
const Home: FC = () => {
  const [prevFlag, setPrevFlag] = useState(true);
  const [disabledScroll, setDisabledScroll] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  let topValue = 0, // 上次滚动条到顶部的距离
    interval: any = null; // 定时器

  function handleScrollStop() {
    if (document.documentElement.scrollTop === topValue) {
      clearInterval(interval);
      setDisabledScroll(false);
      clearInterval(interval);
      interval = null;
    }
  }
  useLayoutEffect(() => {
    document.getElementById(
      'swiper-home-container'
    )!.style.height = `calc(${document.documentElement.clientHeight}px )`;
    document.getElementById(
      'first-screen'
    )!.style.height = `calc(${document.documentElement.clientHeight}px - 4.75rem)`;
    document.getElementById(
      'second-container-p1'
    )!.style.height = `calc(${document.documentElement.clientHeight}px  - 4.75rem)`;
    if (isPC) {
      // document.getElementById("inner")!.style.height = "35.375rem";
      // document.getElementById("swiper-container")!.style.height = "35.375rem";
    }
  });
  const [plain, setPlag] = useState(true);

  const { isPC }: Viewport = useViewport();
  const screenOneClass: string = classnames(isPC ? '' : 'screen-one');
  const screenOneClassFirst: string = classnames(
    'relative w-full h-full',
    isPC ? 'mt-[4.75rem]' : 'pt-[4.75rem]'
  );
  const threeOneClass: string = classnames(
    'pt-[4.75rem]',
    isPC ? '' : 'second-container'
  );

  return (
    <>
      <Header isPlain={plain} isLight={false} />
      <div className='home-wrapper  bg-floor'>
        <Swiper
          modules={[Mousewheel, FreeMode, Scrollbar]}
          id='swiper-home-container'
          onSlideChange={(item) => {
            setDisabledScroll(true);
            setCurrentIndex(item.activeIndex);
            setPlag(item.isBeginning);
          }}
          autoHeight={true}
          simulateTouch={false}
          allowSlidePrev={prevFlag}
          mousewheel={true}
          direction='vertical'
          speed={500}
        >
          <SwiperSlide>
            <section className={screenOneClass}>
              <div className={screenOneClassFirst} id='first-screen'>
                <div className='text-center flex items-center justify-center flex-col  h-[55%] mx-[32px]'>
                  <span className='title text-title font-poppins-600'>
                    Revolutionizing DeFi with NFTs
                  </span>
                  <span className='subtitle font-poppins-400'>
                    Financial NFT Labs is dedicated to supporting and funding
                    the financial NFT ecosystem built upon Solv Protocol and
                    EIP-3525. We aim to accelerate a new paradigm shift in DeFi
                    with financial NFTs and NFT financialization.
                  </span>
                </div>
                <div className='absolute bottom-0 left-0 sea w-full'></div>
              </div>
            </section>
          </SwiperSlide>
          {/* <SwiperSlide>

          </SwiperSlide> */}
          <SwiperSlide className='relative'>
            <Swiper
              onScroll={(swiper, _event: WheelEvent) => {
                if (currentIndex === 1) {
                  if (interval == null) {
                    // 未发起时，启动定时器，1秒1执行
                    interval = setInterval(handleScrollStop, 1000);
                    topValue = document.documentElement.scrollTop;
                  }
                }

                if (disabledScroll) {
                  swiper.translateTo(0, 0);
                }

                setPrevFlag(swiper.isBeginning);
              }}
              autoHeight={true}
              draggable={false}
              direction={'vertical'}
              slidesPerView={'auto'}
              freeMode={true}
              scrollbar={{ draggable: true }}
              mousewheel={{
                releaseOnEdges: true,
              }}
              className={threeOneClass}
              modules={[FreeMode, Scrollbar, Mousewheel]}
              simulateTouch={false}
              id='second-container'
              speed={1000}
            >
              <SwiperSlide className='bg-white'>
                <Container
                  size={'second'}
                  id='second-container-p1'
                  className='flex items-center justify-between flex-row h-full second-container-inner'
                >
                  <div className='flex flex-col second-container-module'>
                    <p className='second-title block mb-[2.1875rem] font-poppins-600'>
                      A trillion-dollar market
                    </p>
                    <p className='second-subtitle block font-poppins-400'>
                      Financial NFT and NFT financialization is bringing more
                      sophisticated financial instruments and flexible
                      transaction models to create a multi-trillion-dollar
                      market.
                    </p>
                  </div>
                  <div className='flex flex-col items-center justify-between'>
                    <img
                      src={diamonds}
                      alt=''
                      className='w-[56rem] max-w-[56rem] flex flex-col translate-y-[7%] market-img'
                    />
                  </div>
                </Container>

                <section>
                  <div className='bg-[#F2F6FB] pt-[5rem] pb-[5.2rem]'>
                    <Container
                      className='second-container-inner'
                      size={'second'}
                    >
                      <div className='mb-[3.2rem] flex flex-col second-container-module'>
                        <p className='three-title font-poppins-600'>EIP-3525</p>
                        <p className='three-title font-poppins-600'>
                          the Financial NFT standard
                        </p>
                      </div>
                      <div className='flex flex-row justify-between item-modules'>
                        <div className='flex-1 flex-col item-module'>
                          <div className='item'>
                            <div className='h-[6.375rem] flex flex-col items-start'>
                              <img
                                src={a3}
                                className='w-[4.25rem] standard-img'
                                alt='Built for sophisticated financial instruments'
                              />
                            </div>
                            <span className='flex eip font-poppins-600'>
                              Built for sophisticated financial instruments
                            </span>
                            <span className='flex eip-sub font-poppins-400'>
                              EIP-3525 combines the power of fungibles and
                              non-fungibles to fulfill the identification- and
                              quantitative-processing demands of DeFi.
                            </span>
                          </div>
                        </div>
                        <div className='flex-1 flex-col item-module'>
                          <div className='item'>
                            <div className='h-[6.375rem] flex flex-col items-start'>
                              <img
                                src={b3}
                                className='w-[4.63rem] standard-img'
                                alt='Splittable, tradable NFTs'
                              />
                            </div>
                            <span className='flex eip font-poppins-600'>
                              Splittable <br /> tradable NFTs
                            </span>
                            <span className='flex eip-sub font-poppins-400'>
                              EIP-3525 allows users to trade, split, merge, or
                              use NFTs any other manner they prefer.
                            </span>
                          </div>
                        </div>
                        <div className='flex-1 flex-col item-module'>
                          <div className='item'>
                            <div className='h-[6.375rem] flex flex-col items-start'>
                              <img
                                src={c3}
                                className='w-[5.4rem] standard-img mt-[0.15rem]'
                                alt='Backward-compatible with ERC-721'
                              />
                            </div>
                            <span className='flex eip font-poppins-600'>
                              Backward-compatible with ERC-721
                            </span>
                            <span className='flex eip-sub font-poppins-400'>
                              EIP-3525 integrates with the existing NFT
                              infrastructure seamlessly, creating a hassle-free
                              user experience.
                            </span>
                          </div>
                        </div>
                      </div>
                    </Container>
                  </div>
                </section>
                <section className='pb-[4rem]'>
                  <div
                    className={`flex items-center justify-center  bg-white relative`}
                  >
                    {isPC ? (
                      <Container size={'second'} className='flex flex-row'>
                        <div className='mt-[6.25rem] min-h-[10rem] w-full'>
                          <div className='text-[3.75rem] w-full font-poppins-600 text-black-80'>
                            The ecosystem
                          </div>
                          <div className='w-full mt-[4rem]'>
                            {datas.map((item, index) => (
                              <div
                                className='w-full text-center mb-[7.6875rem] flex justify-between items-center'
                                key={item.subtitle}
                              >
                                {index % 2 === 0 ? (
                                  <>
                                    <div className='w-[50%] text-left'>
                                      <Link to={item.link}>
                                        <div
                                          className={`font-poppins-600 text-[1.5rem] txt2 txt-${
                                            index + 1
                                          } flex`}
                                        >
                                          {item.subtitle}
                                          <img
                                            src={LinkLine}
                                            alt=''
                                            className='w-[1rem] ml-[0.75rem]'
                                          />
                                        </div>
                                      </Link>
                                      <p className='mt-[1.5rem] w-[100%] text-[1.3rem] text-black-60'>
                                        {item.describe}
                                      </p>
                                    </div>
                                    <div className='flex-1 flex justify-start'>
                                      <img
                                        src={item.img}
                                        alt=''
                                        className='ml-[12.5rem] w-[16.125rem] mt-[1.5rem]'
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className='w-[50%] flex justify-end'>
                                      <img
                                        src={item.img}
                                        alt=''
                                        className='mr-[12.5rem] w-[16.125rem] mt-[1.5rem]'
                                      />
                                    </div>
                                    <div className='w-[50%] text-left'>
                                      <Link to={item.link}>
                                        <div
                                          className={`font-poppins-600 text-[1.5rem] txt2 txt-2 flex`}
                                        >
                                          {item.subtitle}
                                          <img
                                            src={LinkLine}
                                            alt=''
                                            className='w-[1rem] ml-[0.75rem]'
                                          />
                                        </div>
                                      </Link>
                                      <p className='mt-[1.5rem] text-[1.3rem] text-black-60'>
                                        {item.describe}
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </Container>
                    ) : (
                      <Container size={'second'} className='flex flex-row'>
                        <div className='mt-[6.25rem] min-h-[10rem] w-full'>
                          <div className='text-[2rem] w-full text-center font-poppins-600'>
                            The ecosystem
                          </div>
                          <div className='w-full mt-[4rem]'>
                            {datas.map((item) => (
                              <div
                                className='w-full text-center mb-[4rem]'
                                key={item.subtitle}
                              >
                                <Link to={item.link}>
                                  <div className='font-poppins-600 text-[1.5rem] txt2 flex items-center justify-center'>
                                    {item.subtitle}
                                    <img
                                      src={LinkLine}
                                      alt=''
                                      className='w-[1rem] ml-[0.75rem]'
                                    />
                                  </div>
                                </Link>
                                <p className='mt-[1.5rem] text-base text-black-60'>
                                  {item.describe}
                                </p>
                                <img
                                  src={item.img}
                                  alt=''
                                  className='w-full mt-[1.5rem]'
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </Container>
                    )}
                  </div>
                </section>
                <Footer />
              </SwiperSlide>
            </Swiper>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default Home;
