import DSkeleton from "@components/common/Skeleton";

export default function Loading() {
  return (
    <>
      <div className="w-full flex flex-col items-start justify-center loading">
        {/* <img src={AppLogo} alt="" className="w-[4rem] h-[4rem]" /> */}
        <DSkeleton visible={true} itemSize={3}></DSkeleton>
        {/* <p className="mt-4 text-[0.875rem] opacity-40">Loading ...</p> */}
      </div>
    </>
  );
}
