import { SocialMedia, IMapping } from "@apis/model/featuredModel";

import website from "@assets/images/featured/website.svg";
import twitter from "@assets/images/featured/twitter.svg";
import telegram from "@assets/images/featured/telegram.svg";
import discord from "@assets/images/featured/discord.svg";
import linkedin from "@assets/images/featured/linkedin.svg";

const mapping: IMapping = {
  website: website,
  twitter: twitter,
  telegram: telegram,
  discord: discord,
  linkedin: linkedin
};

function IconButton(props: SocialMedia) {
  const jump = (event: React.MouseEvent<HTMLDivElement>) => {
    window.open(props.URI);
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <div
      className="w-[1.25rem] h-[1.25rem] mr-[1.5rem] cursor-pointer"
      onClick={(event) => {
        jump(event);
      }}
    >
      <img src={mapping[props.type]} alt={props.desc} />
    </div>
  );
}

export default IconButton;
