import { Result } from "@apis/model/featuredModel";
import { projectDetailsURL } from "@apis/requestMd";
import discord from "@assets/images/featured/discord.svg";
import telegram from "@assets/images/featured/telegram.svg";
import twitter from "@assets/images/featured/twitter.svg";
import website from "@assets/images/featured/website.svg";
import linkedin from "@assets/images/featured/linkedin.svg";
import BackIcon from "@assets/images/icon/back.svg";
import Loading from "@components/Laoding";
import Container from "@components/layout/Container";
import Wrapper from "@components/layout/Wrapper";
import { useViewport, Viewport } from "@components/ViewPort/viewportContext";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import Markdown from "react-markdown";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import "./detail.css";

const mediaMaps: { [key: string]: { logo: string } } = {
  website: { logo: website },
  twitter: { logo: twitter },
  telegram: { logo: telegram },
  discord: { logo: discord },
  linkedin: { logo: linkedin }
};

function FeaturedContent() {
  const params = useParams();

  const { isPC }: Viewport = useViewport();
  const className: string = classNames(
    isPC ? "w-[20rem] min-h-[32.375rem]" : "min-h-[32.375rem]"
  );
  const markdownClass = classNames(
    "flex-1 mt-[5rem] d-markdown",
    isPC ? "ml-[6rem]" : "md--d-markdown"
  );

  const { isLoading, error, data } = useQuery("repoData", () =>
    fetch(projectDetailsURL, {
      method: "POST",
      body: JSON.stringify({
        name: params.name || "",
      }),
    }).then((res) => res.json()),{cacheTime: 0}
  );

  if (isLoading) {
    return (
      <div className="mt-8 w-full">
        <Loading></Loading>
      </div>
    );
  }
  if (error) {
    return <div></div>;
  }

  if (!data.success) {
    return <div></div>;
  }

  let featuredProject: Result = data.result;

  return (
    <>
      <div className={className}>
        <div className="absolute top-[-6rem]">
          <div
            className=" inline-block"
            onClick={() => {
              window.history.back();
            }}
          >
            <div className="flex mb-4 cursor-pointer hover:opacity-90 items-center">
              <img src={BackIcon} alt="" className="" />
              <span className="font-semibold text-base text-white ml-2">
                Back
              </span>
            </div>
          </div>

          <div
            className={`bg-white rounded-[6px] p-2 flex flex-col items-center ${
              isPC ? "w-[20rem]" : "w-full"
            }`}
          >
            <div className="w-full flex justify-end">
              <div className="bg-[#f2f3f5] rounded-[2px] text-[0.875rem] font-semibold p-1 text-black3">
                {featuredProject.tags}
              </div>
            </div>
            <img
              src={featuredProject.logoURI}
              alt=""
              className="w-[7.5rem] h-[7.5rem] rounded-[50%] mt-4 drop-shadow-3xl"
            />
            <p className="pt-[2rem] font-semibold text-[2.5rem] text-black-80 leading-none text-center">
              {featuredProject.name}
            </p>
            <p className="text-base text-black-60 font-normal px-8 text-center pt-6">
              {featuredProject.desc}
            </p>
            <span className="inline-block text-[.8125rem] text-[#2264ED] min-w-[9.125rem] min-h-[1.5rem] bg-blue-100/80 rounded-[.25rem]  text-center px-[1rem] py-[.1875rem] mt-[1.5rem] mb-[3.375rem]">
              {featuredProject.status}
            </span>
            <div className="flex justify-around w-full px-16 pb-[5.5rem]">
              {featuredProject.socialMedia.map(
                (item) =>
                  mediaMaps[item.type] && (
                    <img
                      src={mediaMaps[item.type].logo}
                      alt="logo"
                      className="w-[1.25rem] h-[1.25rem] cursor-pointer"
                      key={item.type}
                      onClick={() => {
                        const mediaList = featuredProject.socialMedia.filter(
                          (media: any) => {
                            return media.type === item.type;
                          }
                        );
                        if (mediaList.length > 0) {
                          window.open(mediaList[0].URI, "_blank");
                        }
                      }}
                    />
                  )
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={markdownClass}>
        <Markdown>{featuredProject.contents.replace(/ {7}/g, "")}</Markdown>
      </div>
    </>
  );
}

export default function FeaturedProjects() {
  const [scrollLight, setScrollLight] = useState(true);

  const { isPC }: Viewport = useViewport();
  const className: string = classNames("relative", isPC ? "flex" : "");

  useMemo(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let _scroll = (event: Event) => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (scrollTop >= 180) {
        setScrollLight(false);
      } else {
        setScrollLight(true);
      }
    };
    window.addEventListener("scroll", _scroll);

    return () => {
      window.removeEventListener("scroll", _scroll);
      _scroll = () => {};
    };
  }, []);

  return (
    <Wrapper isTop={true} scrollTop={0} isLight={scrollLight}>
      <div className="relative">
        <div className="w-full h-[14rem] featured-project-header"></div>
        <Container className={className} size={"second"}>
          <FeaturedContent></FeaturedContent>
        </Container>
      </div>
    </Wrapper>
  );
}
