import AppLogoSingle from "@assets/images/icon/app-logo-single.svg";
import AppLogoWhite from "@assets/images/icon/app-logo-white.svg";
import AppLogo from "@assets/images/icon/app-logo.svg";
import DownLineActiveIcon from "@assets/images/icon/down-line-active.svg";
import DownLineIcon from "@assets/images/icon/down-line.svg";
import NavIconLight from "@assets/images/icon/nav-icon-light.svg";
import NavIcon from "@assets/images/icon/nav-icon.svg";
import DButton from "@components/common/Button";
import Documentation, { menuGroup } from "@components/Documentation";
import Container from "@components/layout/Container";
import { useViewport, Viewport } from "@components/ViewPort/viewportContext";
import { Popover, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import React, { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./index.css";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Header(props: {
  isLight?: boolean;
  isPlain?: boolean;
}) {
  const location = useLocation();

  const [openDocumentation, setOpenDocumentation] = useState(false);

  const { isPC }: Viewport = useViewport();
  const appLogoClass: string = classNames("w-auto", isPC ? "h-8" : "h-6");

  return (
    <div
      className={`solvheader ${isPC ? "" : "md-solvheader"} backdrop-blur-md`}
    >
      <Popover className="relative text-an-10">
        <Container size={"second"}>
          <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <div>
                <span className="sr-only">Workflow</span>
                <Link to="/">
                  <img
                    className={appLogoClass}
                    src={props.isLight ? AppLogoWhite : AppLogo}
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <img
                  src={props.isLight ? NavIconLight : NavIcon}
                  alt=""
                  className="h-6 w-6"
                  aria-hidden="true"
                />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden md:flex space-x-10">
              <div className="flex items-center text-base font-medium text-black-60 hover:text-black cursor-pointer nav-menu__item">
                <Link to="/featured">
                  <span
                    className={classNames(
                      location.pathname.indexOf("/featured") > -1
                        ? "font-poppins-600 " +
                            `${props.isLight ? "text-white" : "text-black"}`
                        : ""
                    )}
                  >
                    Featured Projects
                  </span>
                </Link>
              </div>
              <div className="flex items-center text-base font-medium text-black-60 hover:text-black cursor-pointer nav-menu__item">
                <Link to="/wishlist">
                  <span
                    className={classNames(
                      location.pathname.indexOf("/wishlist") > -1
                        ? "font-poppins-600 text-black"
                        : "",
                      props.isLight ? "text-white-80" : ""
                    )}
                  >
                    Wishlist
                  </span>
                </Link>
              </div>
              <div className="flex items-center text-base font-medium cursor-pointer nav-menu__item">
                <Documentation>
                  <span
                    className={classNames(
                      props.isLight ? "text-white-80" : "",
                      "font-poppins-400"
                    )}
                  >
                    Documentation
                  </span>
                </Documentation>
              </div>
              <div className="flex items-center text-base font-medium text-black-60 hover:text-black cursor-pointer nav-menu__item">
                <Link to="/insights">
                  <span
                    className={classNames(
                      location.pathname.indexOf("/insights") > -1
                        ? "font-poppins-600 text-black"
                        : "",
                      props.isLight ? "text-white-80" : ""
                    )}
                  >
                    Insights
                  </span>
                </Link>
              </div>
            </Popover.Group>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <DButton
                plain={props.isPlain ? true : false}
                light={props.isLight ? true : false}
                onClick={() => {
                  window.open(
                    "https://docs.google.com/forms/d/e/1FAIpQLSdBOZlDmUX4HYj_6EDEHtGer2cXNXreN4M4R9E9uy9joE587A/viewform",
                    "_blank"
                  );
                }}
              >
                Apply Now
              </DButton>
            </div>
          </div>
        </Container>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute h-[100vh] bg-white top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="h-[100%] rounded-lg shadow-lg ring-1 ring-black ring-opacity-5  divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src={AppLogoSingle}
                      alt="Workflow"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="rounded-md p-2 bg-transparent inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-8 pb-8 h-[82vh] overflow-y-auto text-black-60">
                  <Link to="/featured">
                    <div
                      className={classNames(
                        "mt-[1rem]",
                        location.pathname.indexOf("/featured") > -1 &&
                          !openDocumentation
                          ? "font-poppins-600 text-black"
                          : ""
                      )}
                    >
                      Featured Projects
                    </div>
                  </Link>
                  <Link to="/wishlist">
                    <div
                      className={classNames(
                        "mt-[1rem]",
                        location.pathname.indexOf("/wishlist") > -1 &&
                          !openDocumentation
                          ? "font-poppins-600 text-black"
                          : ""
                      )}
                    >
                      Wishlist
                    </div>
                  </Link>
                  <div className={classNames("mt-[1rem]")}>
                    <div
                      onClick={() => {
                        setOpenDocumentation(!openDocumentation);
                      }}
                    >
                      <div
                        className={classNames(
                          "flex items-center justify-between",
                          openDocumentation ? "font-poppins-600 text-black" : ""
                        )}
                      >
                        Documentation
                        <img
                          src={
                            !openDocumentation
                              ? DownLineIcon
                              : DownLineActiveIcon
                          }
                          alt=""
                          className={classNames(
                            "w-[1rem]",
                            openDocumentation ? "rotate-180" : ""
                          )}
                        />
                      </div>
                    </div>
                    {openDocumentation && (
                      <div className="mt-[1rem] ml-[1rem]">
                        {menuGroup.map((item) => (
                          <div key={item.label}>
                            <div className="font-poppins-600 text-base mb-[1rem] text-black-80">
                              {item.label}
                            </div>

                            {item.list.map((child) => (
                              <div
                                className="text-base mb-[1rem]"
                                key={child.label}
                                onClick={() => {
                                  setOpenDocumentation(false);
                                  window.open(child.path, "_blank");
                                }}
                              >
                                {child.label}
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <Link to="/insights">
                    <div
                      className={classNames(
                        "mt-[1rem]",
                        location.pathname.indexOf("/insights") > -1 &&
                          !openDocumentation
                          ? "font-poppins-600 text-black"
                          : ""
                      )}
                    >
                      Insights
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}
