// 响应式布局组件封装
// 尺寸暂定为：
// width < 820 为移动端
// width >=820 && width< 1024 为PC端

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface IProps {
  children: ReactNode;
}

interface IContext {
  pageWidth: number;
  pageHeight: number;
}

export interface Viewport extends IContext {
  isPC: boolean;
}

const contextObj: IContext = { pageWidth: 0, pageHeight: 0 };
const viewportContext = createContext(contextObj);

const ViewportProvider = ({ children }: IProps) => {
  // 监听页面宽度、高度
  const [pageWidth, setWidth] = useState(window.innerWidth);
  const [pageHeight, setHeight] = useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ pageWidth, pageHeight }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = (): Viewport => {
  const { pageWidth, pageHeight } = useContext(viewportContext);
  const mobileWidth = 820;
  const isPC = pageWidth > mobileWidth;
  return { pageWidth, pageHeight, isPC };
};

export { ViewportProvider, useViewport };
