import Loading from '@components/Laoding';
import { useViewport, Viewport } from '@components/ViewPort/viewportContext';
import c from 'classnames';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import './index.css';

type WishListItem = {
  title: string;
  active: boolean;
  contents: Array<{
    title: string;
    logoURI: string;
    props: Array<string>;
  }>;
};

function WishList(props: { list: WishListItem[] }) {
  const [wishList, setWishList] = useState<WishListItem[]>([]);

  const { isPC }: Viewport = useViewport();
  const className: string = c(
    'wish-item__label flex items-center',
    isPC ? 'p-[1.4rem]' : 'p-[1rem]'
  );

  useEffect(() => {
    const _wishList: WishListItem[] = JSON.parse(
      JSON.stringify(props.list)
    ).map((item: WishListItem) => {
      item.active = false;
      return item;
    });
    setWishList([..._wishList]);

    return () => {};
  }, [props.list]);

  return (
    <div className='w-full pt-[1.25rem] relative z-10'>
      <div className='w-full wish-list'>
        {wishList.map((item) => (
          <div className='wish-item' key={item.title}>
            <div
              className={` ${className} ${item.active ? 'wish-active' : ''}`}
              onClick={() => {
                item.active = !item.active;
                setWishList([...wishList]);
              }}
            >
              <div className='unit w-[2rem]'>{item.active ? '-' : '+'}</div>
              <span className='font-poppins-600'>{item.title}</span>
            </div>
            <div className={!item.active ? 'hidden' : ''}>
              <WishItem item={item}></WishItem>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function WishItem(props: { item: WishListItem }) {
  const { isPC }: Viewport = useViewport();
  const className: string = c(
    ' flex flex-col justify-between',
    isPC
      ? 'w-[50%] pt-[3.6rem] pl-[3.6rem] pr-[3.6rem]'
      : 'w-full pt-[3rem] pl-[3rem] pr-[3rem]'
  );

  return (
    <div className='wish-item__body pb-[3.6rem] flex flex-wrap'>
      {props.item.contents.map((content) => (
        <div className={className} key={content.title}>
          <div className='flex-1'>
            <img
              src={content.logoURI}
              alt=''
              className='w-[5rem] h-[5rem] ml-[-1rem] mt-[-1rem] mb-[-1.8rem]'
            />
            <p className='mt-8 text-[1.25rem] font-poppins-600 text-black-80'>
              {content.title}
            </p>
            <div className='mt-4'>
              {content.props.map((prop) => (
                <div
                  className='text-base leading-[22.4px] font-poppins-600 text-black-60 flex items-start'
                  key={prop}
                >
                  <p className='mr-2'>•</p>
                  <p className='font-poppins-400 first-letter:capitalize'>
                    {prop}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default function WishlistData() {
  const { isLoading, error, data } = useQuery('repoData', () =>
    fetch('https://financial-nft-labs-worker.solv.workers.dev/wishlist', {
      method: 'POST',
    }).then((res) => res.json())
  );

  if (isLoading) {
    return <Loading></Loading>;
  }

  if (error) {
    return <div></div>;
  }

  if (!data.success) {
    return <div></div>;
  }

  return (
    <>
      <WishList list={data.result}></WishList>
    </>
  );
}
