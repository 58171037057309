import { useViewport, Viewport } from "@components/ViewPort/viewportContext";
import c from "classnames";
import "./index.css";

export default function Container(props: {
  size?: "" | "second"; // default(''): 880px | 55rem; second: 1200px | 75rem;
  isTop?: boolean;
  children?: React.ReactNode;
  className?: string;
  id?: string;
}) {
  const { isPC }: Viewport = useViewport();
  const className: string = c(
    "mx-auto",
    isPC ? (props.size ? "w-container-second" : "w-container") : "w-mobile",
    props.className
  );

  return (
    <div className={className} id={props.id}>
      {props.children}
    </div>
  );
}
