import PageErrorFallback from "@components/Lib/BeautifulError";
import ErrorBoundary from "@components/Lib/ErrorBoundary";
import App from "@pages/App";
import i18n from "i18next";
import React from "react";
import ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { default as tCN, default as tEN } from "./locales/en/translation.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: tEN,
      },
      cn: {
        translation: tCN,
      },
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

ReactDOM.render(
  <ErrorBoundary fallbackRender={PageErrorFallback}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById("app")
);
