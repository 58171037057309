import React from "react";
import { Outlet } from "react-router-dom";

const MainLayout: React.FC = (): JSX.Element => {
  return (
    <div className="app relative">
      <Outlet />
    </div>
  );
};
export default React.memo(MainLayout);
