import documentationIcon from '@assets/images/icon/documentation.svg';
import DPopover from '@components/common/Popover';
import React from 'react';
import './index.css';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type MenuGroupListItem = {
  label: string;
  path: string;
  type: string; // router | href
};

type MenuGroupItem = {
  label: string;
  list: Array<MenuGroupListItem>;
};

export const menuGroup: Array<MenuGroupItem> = [
  {
    label: 'The Theory',
    list: [
      {
        label: 'Financial NFTs',
        path: 'https://docs.solv.finance/solv-documentation/financial-nfts/financial-nfts',
        type: 'href',
      },
      {
        label: 'The New Token Standard',
        path: 'https://docs.solv.finance/solv-documentation/financial-nfts/EIP-3525',
        type: 'href',
      },
      {
        label: 'Litepaper',
        path: 'https://docs.solv.finance/solv-documentation/financial-nfts/litepaper',
        type: 'href',
      },
    ],
  },
  {
    label: 'Core Mechanism',
    list: [
      {
        label: 'Voucher',
        path: 'https://docs.solv.finance/solv-documentation/core-mechanism/voucher',
        type: 'href',
      },
      {
        label: 'id-slot-units mechanism',
        path: 'https://docs.solv.finance/solv-documentation/core-mechanism/id-slot-units',
        type: 'href',
      },
      {
        label: 'Underlying container pattern',
        path: 'https://docs.solv.finance/solv-documentation/core-mechanism/underlying-container',
        type: 'href',
      },
      {
        label: 'Glossary',
        path: 'https://docs.solv.finance/solv-documentation/core-mechanism/glossary',
        type: 'href',
      },
    ],
  },
  {
    label: 'Featured Products',
    list: [
      {
        label: 'Vesting Voucher',
        path: 'https://docs.solv.finance/solv-documentation/featured-products/vesting-voucher',
        type: 'href',
      },
      {
        label: 'Convertible Voucher',
        path: 'https://docs.solv.finance/solv-documentation/featured-products/convertible-voucher',
        type: 'href',
      },
    ],
  },
  {
    label: 'White paper',
    list: [
      {
        label: 'Financial NFT white paper',
        path: '',
        type: 'href',
      },
    ],
  },
  {
    label: 'Developer',
    list: [
      {
        label: 'EIP-3525',
        path: 'https://github.com/ethereum/EIPs/blob/master/EIPS/eip-3525.md',
        type: 'href',
      },
      {
        label: 'Reference implementation',
        path: 'https://github.com/solv-finance/solv-v2-voucher/tree/main/packages/solv-vnft-core',
        type: 'href',
      },
    ],
  },
  {
    label: 'Others',
    list: [
      {
        label: 'FAQs',
        path: 'https://docs.solv.finance/solv-documentation/others/faqs',
        type: 'href',
      },
      {
        label: 'Tutorials',
        path: 'https://docs.solv.finance/solv-documentation/others/tutorials',
        type: 'href',
      },
    ],
  },
];

export default function Documentation({
  children,
}: {
  children: React.ReactNode;
}) {
  const openDocumentation = (item: MenuGroupListItem) => {
    if (!item.path) return;
    window.open(item.path, '_blank');
  };

  return (
    <DPopover mode={'hover'} buttonNode={children}>
      <div className='relative grid bg-white px-5 py-6 sm:p-8'>
        <div className='flex items-center'>
          <img
            src={documentationIcon}
            alt=''
            className='w-[1.625rem] h-[2rem]'
          />
          <div className='px-4'>
            <p className='text-xl font-bold text-black-80 font-poppins-600'>
              Documentation
            </p>
            <p className='text-sm text-black-60'>Currently backed by solv</p>
          </div>
        </div>
        <div className='flex flex-wrap'>
          {menuGroup.map((item) => (
            <div className='text-base w-[33.33%] mt-10' key={item.label}>
              <p className='font-semibold mb-4 text-[#333333] font-poppins-600'>
                {item.label}
              </p>
              {item.list.map((child: MenuGroupListItem) => (
                <p
                  className='mb-4 font-normal text-black-60 last:mb-0 cursor-pointer hover:text-primary1'
                  key={child.label}
                  onClick={() => openDocumentation(child)}
                >
                  {child.label}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </DPopover>
  );
}
