import Container from "@components/layout/Container";
import Wrapper from "@components/layout/Wrapper";
import WishlistData from "@components/wishlist/Data";
import WishlistIntro from "@components/wishlist/Intro";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

export default function Wishlist() {
  return (
    <Wrapper>
      <Container>
        <WishlistIntro></WishlistIntro>
        <QueryClientProvider client={queryClient}>
          <WishlistData></WishlistData>
        </QueryClientProvider>
      </Container>
    </Wrapper>
  );
}
