import Loading from "@components/Lib/Loading";
import PageNotFoundView from "@components/Lib/PageNotFoundView";
import MainView from "@components/MainView";
import Featured from "@pages/Featured";
import FeaturedProject from "@pages/Featured/ProjectDetail";
import Insights from "@pages/insights";
import MainLayout from "@pages/MainLayout";
import Wishlists from "@pages/Wishlists";
import React, { Suspense } from "react";
import { Navigate, RouteObject } from "react-router-dom";

const Layout = () => (
  <Suspense fallback={<Loading />}>
    <MainLayout />
  </Suspense>
);

const Routes: RouteObject[] = [];
const mainRoutes = {
  path: "/",
  element: <Layout />,
  children: [
    {
      path: "/wishlist",
      element: <Wishlists />,
    },
    {
      path: "/insights",
      element: <Insights />,
    },
    {
      path: "/featured",
      element: <Featured />,
    },
    {
      path: "/featured-project/:name",
      element: <FeaturedProject />,
    },
    { path: "*", element: <Navigate to="/404" /> },
    { path: "/", element: <MainView /> },
    { path: "404", element: <PageNotFoundView /> },
  ],
};

Routes.push(mainRoutes);
export default Routes;
