import WishlistIntroImg from '@assets/images/wishlist/wishlist-intro.svg';
import { useViewport, Viewport } from '@components/ViewPort/viewportContext';
import c from 'classnames';

export default function WishlistIntro() {
  const { isPC }: Viewport = useViewport();
  const className: string = c(
    'absolute w-[39.875ren] h-[39.875rem] right-0 top-[3.25rem]',
    isPC ? '' : 'hidden'
  );

  return (
    <div className='w-full flex items-center mb-[5.4rem]'>
      <div className='w-[33.75rem] mt-[4rem]'>
        <p className='font-poppins-600 text-black-80 text-title'>Wishlist</p>
        <p className='font-normal	text-[1.275rem] text-black-60 font-poppins-400'>
          The main goal of Financial NFT Labs is to ensure the growth and
          diversity of the financial NFT ecosystem. Multi-dimensional, strategic
          partnerships between innovative teams that offer DeFi and GameFi
          products and services are therefore a fundamental part of this
          initiative.
          <br />
          <br />
          Currently, there is a pressing need for the following areas to be
          addressed, with the use of financial NFTs.
        </p>
      </div>
      <img className={className} src={WishlistIntroImg} alt='' />
    </div>
  );
}
